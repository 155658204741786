@import "./reset.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    color: white;
    text-decoration: none;
    z-index: 2;
    width: 100%;
    height: 100%;
    overflow: auto;
}
